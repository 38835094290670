import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <div className="footer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-4 offset-1 col-sm-2">
                        <h5>Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to='/home'>Home</Link></li>
                            <li><Link to='/aboutus'>About Us</Link></li>
                            <li><Link to='/menu'>Products</Link></li>
                            <li><Link to='/contactus'>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-7 col-sm-5">
                        <h5>Our Address</h5>
                        <address>
                            Coney Island Area<br />
		              Brooklyn, NY<br />
		              11224<br />
                            <i className="fa fa-phone fa-lg"></i>: +1 646 389 1299<br />
                            <i className="fa fa-fax fa-lg"></i>: +1 646 389 1299<br />
                            <i className="fa fa-envelope fa-lg"></i>: <a href="mailto:confusion@food.net">
                                info@drivenbyfury.com</a>
                        </address>
                    </div>
                    <div className="col-12 col-sm-4 align-self-center">
                        <div className="text-center">
                            <a className="btn btn-social-icon btn-facebook" href="https://www.facebook.com/DrivenByFury"><i className="fa fa-facebook"></i></a>
                            <a className="btn btn-social-icon btn-twitter" href="https://twitter.com/DrivenByFury"><i className="fa fa-twitter"></i></a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <p>© Copyright 2022 Driven By Fury</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;